<template>
    <div class="webview-container">
        <iframe
            class="template-iframe"
            ref="iframe"
            :src="src"
            @load="loaded"
        ></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            src: "",
        };
    },
    components: {},
    created() {
        const src = sessionStorage.getItem("webview");
        this.src = src;
    },
    methods: {
        loaded() {},
    },
};
</script>

<style lang="scss" scoped>
.template-iframe {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
</style>